<!-- eslint-disable no-empty -->
<template>
    <div class="container" style="overflow-x: hidden;overflow-y: scroll;" >
        <div class="container">
            <div class="containerBg" style="z-index: -2;"></div>
            <!-- 顶栏 -->
            <header class="fx-r fx-bc fx-ac"
                style="z-index: 1;border-radius: 20px;border-top-left-radius: 0px;border-top-right-radius: 0px;">
                <div class="fx-g1" style="padding-left: 100px;">
                    <div class="fx-r">
                        <div class="logo"></div>
                    </div>
                </div>

                <div class="fx-r fx-ae" style="margin-right: 80px;">
                    <div class="link">
                        <a :href="sellerwebsite" target="_blank">商家后台管理</a>
                    </div>
                </div>
            </header>
            <!-- 第一块 -->
            <div class="content" style="z-index: -2;">
                <div class="fx-r fx-bc fx-ac" style="position: relative;">
                    <div class="fx-r fx-bc fx-ac">
                        <div class="fx-h fx-ac fx-bc" style="margin-right:250px;position: relative;">
                            <div style="font-size: 20px;color: white;margin-bottom: 15px;">多种支付, 精准推流</div>
                            <div style="z-index: -2;">
                                <img src="../assets/images/model.png" alt="" style="height: 70vh;width: 100%">
                            </div>
                        </div>
                        <div class="fx-h fx-ac fx-bc" style="margin-right: 20px;margin-left: 20px;z-index: -2;">
                            <div style="font-size: 20px;margin-bottom: 20px;color: white;">做生意，驻界联</div>
                            <div>
                                <div v-if="showqrcode" class="ewm"></div>
                                <vue-qr class="ewm2" v-else-if="!showqrcode && this.sys =='android'" :logoSrc='this.url' :text="this.anddownload" :size="258"></vue-qr>
                                <vue-qr class="ewm2" v-else  :logoSrc="this.url" :text="this.iosdownload" :size="258"></vue-qr>
                            </div>
                            <div v-if="showqrcode" style="font-size: 20px;margin-top: 20px ;color:white;">扫描二维码关注公众号</div>
                            <div v-else-if="!showqrcode && this.sys =='android'" style="font-size: 20px;margin-top: 20px ;color:white;">扫描二维码下载安卓 App</div>
                            <div v-else style="font-size: 20px;margin-top: 20px ;color:white;">扫描二维码下载Ios App</div>
                        </div>
                    </div>
                    <div class="fx-h fx-ac fx-bc" style="margin-left: 20px;z-index: 0;position: relative;">
                        <button class="download-btn" @click="showgzh()">官方公众号</button>
                        <button id="android" class="download-btn" @click="changecode(),changesys($event)" style="padding-left: 65px;"><img style="position: absolute;left: 38px;top: 142px; background-size: 100% 100%;height: 50px;width: 50px;"  src="../assets/images/android.png" alt=""/>安卓下载</button>
                        <button id="ios" class="download-btn" @click="changecode(),changesys($event)" style="padding-left: 65px;"><img style="position: absolute;left: 38px;top: 250px;background-size: 100% 100%;height: 50px;width: 50px;" src="../assets/images/iphone.png" alt=""/>IOS下载</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- 第二块 -->
        <section style="z-index: -2;">
            <div class="content2 fx-r fx-ac fx-bc"
                style="background-image: url('https://img.jldsq.com/2024/03/148fd57de5154e47799ad87d6ae4e3502e.png');">
                <div class="fx-h fx-ac fx-bc txt"
                    style="margin-right: 300px;">
                    <div style="margin-bottom: 50px;">品类多样</div>
                    <div style="">一站购齐</div>
                </div>
                <img src="../assets/images/model-2.png" alt="" style="height: 75vh;width: 18vw;">
            </div>
        </section>
        <!-- 第三块 -->
        <section style="z-index: -2;">
            <div class="content2 fx-r fx-ac fx-bc"
                style="background-image: url('https://img.jldsq.com/2024/03/148fd57de5154e47799ad87d6ae4e3502e.png');">
                <img src="../assets/images/model-3.png" alt="" style="height: 75vh;width: 18vw;">
                <div class="fx-h fx-ac fx-bc txt"
                    style="margin-left: 300px;">
                    <div style="margin-bottom: 50px;">多种展示</div>
                    <div>聚焦爆款</div>
                </div>
            </div>
        </section>
        <!-- 第四块 -->
        <section style="z-index: -2;">
            <div class="content2 fx-r fx-ac fx-bc"
                style="background-image: url('https://img.jldsq.com/2024/03/148fd57de5154e47799ad87d6ae4e3502e.png');background-repeat: no-repeat;">

                <div class="txt fx-h fx-ac fx-bc"
                    style="margin-right: 300px;">
                    <div style="margin-bottom: 50px;">专属商家中心</div>
                    <div>多种数据统计</div>
                </div>
                <img src="../assets/images/model-4.png" alt="" style="height: 75vh;width: 18vw;">
            </div>
        </section>
        <!-- 第五块 -->
        <section>
            <div class="content2 fx-h fx-ac fx-bc"
                style="z-index: -2;position: relative; background-image: url('https://img.jldsq.com/2024/03/148fd57de5154e47799ad87d6ae4e3502e.png');background-repeat: no-repeat;">
                <div class="txt" >
                    <div style="margin-bottom: 50px;text-align: center;">专业管理平台</div>
                    <div style="">为您的交易保驾护航</div>
                </div>
                <div
                    style="position: absolute;bottom: 1px;  background: white;width: 100%;color:dimgrey;font-size: 14px;height: 10vh;text-align: center;">
                    <p style="padding-top: 50px;">Copyright @ 2024 jldsq.com All Right Reserved 浙ICP备2022022973号-1 界联科技客服热线：0576-80686858</p>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { getDownloadLink } from '@/api';
import vueQr from 'vue-qr';

export default {
    data() {
        return {
            sellerwebsite: '',
            anddownload:'',
            iosdownload:'',
            url:require('@/assets/images/logo.png'),
            showqrcode:true,
            sys:'android'
        }
    },
    components: {
        vueQr
    },
    methods: {
        changesys(event){
                this.sys=event.currentTarget.id;
                getDownloadLink().then(res => {
                    if(this.sys == 'android'){
                        //this.anddownload = res.data.data.downloadLink+"?app="+this.sys;
                        this.anddownload = res .data .data.andUrl;
                        console.log(this.anddownload);
                    }
                    if(this.sys == 'ios'){
                        // this.iosdownload = res.data.data.downloadLink+"?app="+this.sys;
                        this.iosdownload = res.data.data.iosUrl;
                        console.log(this.iosdownload);
                    }
                })
        },
        showgzh(){
            this.showqrcode=true;
        },
        changecode() {
            this.showqrcode = false ;
        },
        init() {
            getDownloadLink().then(res => {
                this.sellerwebsite = res.data.data.sellerUrl;
                this.download = res.data.data.webSite;
                // console.log(this.download);
            })
        }
    },
    beforeMount() {
        this.init();
    }
}

</script>

<style scoped>
header {
    width: 100vw;
    position: fixed;
    background: #fafafa;
    border-bottom: 1px solid #DDDDDD;
    padding-bottom: 10px;
}

a {
    color: black;
    font-size: 16px;
}

a:link {
    text-decoration: none;
}

a:active {
    text-decoration: none;
}

a:visited {
    text-decoration: none;
}

a:hover {
    text-decoration: none;
    color: brown;
}

.container {
    margin: 0;
    padding: 0;
    position: relative;
    height: 100%;
    width: 100%;
}


.containerBg {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 74vh;
    background: #E93323;
    z-index: -1;
}

.content2 {
    background-attachment: fixed;
    background-size: cover;
    background-position: center center;
    height: 100vh;
}

section {
    height: 100vh;
    color: black;
    justify-content: center;
    align-items: center;
}

.content {
    height: 100vh;
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
}

.txt{
    font-size: 60px;
    color:white;
    font-weight: bold;
}

.logo {
    background: url('https://img.jldsq.com/2024/03/125b5c9217463548e8b0f1f0c30d779018.png') no-repeat;
    height: 60px;
    width: 150px;
    margin-top: 5px;
    background-size: 100% 100%;
    display: inline-flex;
}

.rollimage {
    background-attachment: fixed;
    background-size: cover;
    background-position: center center;
}

.link {
    cursor: pointer;
}

.ewm {
    background-image: url('/src/assets/images/ewm.png');
    background-repeat: no-repeat;
    width: 15vw;
    height: 15vw;
    background-size: 100% 100%;
}

.ewm2{
    width: 15vw;
    height: 15vw;
    background-size: 100% 100%;
}

.download-btn {
    width: 240px;
    height: 80px;
    font-size: 30px;
    margin: 15px;
    background-color: #ff3b3f;
    color: white;
    border-radius: 40px;
    cursor: pointer;
    border-color: white;
}

.download-btn:hover {
    color: #5bc9f8;
}
</style>