import axios from "axios";

export function request(config) {
  let instance = axios.create({
    baseURL: 'https://api.jldsq.com',
    timeout: 5000
  });


  return instance(config);
}

