// 用户管理中的网络请求
import {request} from "./request";


// 获取下载链接
export function getDownloadLink() {
    return request({
      url: '/system/plat/websiteInfo',
      method:'get'
    })
  }